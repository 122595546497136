import { render, staticRenderFns } from "./creatNewPlan.vue?vue&type=template&id=56a75390&scoped=true"
import script from "./creatNewPlan.vue?vue&type=script&lang=js"
export * from "./creatNewPlan.vue?vue&type=script&lang=js"
import style0 from "./creatNewPlan.vue?vue&type=style&index=0&id=56a75390&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "56a75390",
  null
  
)

export default component.exports