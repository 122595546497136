var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"hbVideo"},[_c('ul',{staticClass:"sucai clearfix"},[_c('li',[_vm._m(0),_c('div',{staticClass:"videoBox"},[_c('img',{staticClass:"videoImg",attrs:{"src":_vm.childrenVue.sc.imgUrl,"alt":""}}),_c('div',{staticClass:"addIcon"},[_vm._v("+")]),_c('div',[_vm._v("点击或拖拽素材")])]),_vm._m(1)]),_c('li',[_vm._m(2),_c('div',{staticClass:"videoBox"},[_c('img',{staticClass:"videoImg",attrs:{"src":_vm.childrenVue.sc.videoUrl,"alt":""}}),_c('div',{staticClass:"addIcon"},[_vm._v("+")]),_c('div',[_vm._v("点击或拖拽素材")])]),_vm._m(3)]),_c('li',[_vm._m(4),_c('div',{staticClass:"videoBox"},[_c('img',{staticClass:"videoImg",attrs:{"src":_vm.childrenVue.sc.logoUrl,"alt":""}}),_c('div',{staticClass:"addIcon"},[_vm._v("+")]),_c('div',[_vm._v("点击或拖拽素材")])]),_vm._m(5)])]),_c('div')])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',[_c('i',[_vm._v("*")]),_c('span',[_vm._v("视频")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',[_c('div',[_vm._v("视频尺寸 16:9")]),_c('div',[_vm._v("视频格式 mp4")]),_c('div',[_vm._v("视频大小 < 25600 KB")]),_c('div',[_vm._v("视频时长 0~60 秒")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',[_c('i',[_vm._v("*")]),_c('span',[_vm._v("视频封面图")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',[_c('div',[_vm._v("图片尺寸 1280 x 720")]),_c('div',[_vm._v("图片格式 png/jpg/jpeg")]),_c('div',[_vm._v("图片大小 <150 KB")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',[_c('i',[_vm._v("*")]),_c('span',[_vm._v("品牌 Logo")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',[_c('div',[_vm._v("图片尺寸 200 x 200")]),_c('div',[_vm._v("图片格式 jpg/png/jpeg")]),_c('div',[_vm._v("图片大小 <100 KB")])])
}]

export { render, staticRenderFns }