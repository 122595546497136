import { render, staticRenderFns } from "./editAdvertPlan.vue?vue&type=template&id=53651323&scoped=true"
import script from "./editAdvertPlan.vue?vue&type=script&lang=js"
export * from "./editAdvertPlan.vue?vue&type=script&lang=js"
import style0 from "./editAdvertPlan.vue?vue&type=style&index=0&id=53651323&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "53651323",
  null
  
)

export default component.exports