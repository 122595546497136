var render = function render(){var _vm=this,_c=_vm._self._c;return _c('el-dialog',{staticClass:"icon_custom_popup",attrs:{"visible":_vm.show,"width":"640px","close-on-click-modal":false,"title":_vm.title + '模板详情'},on:{"update:visible":function($event){_vm.show=$event},"close":_vm.onCancel}},[_c('img',{staticClass:"icon",attrs:{"src":require("@/assets/img/suCaiIcon.png")}}),_c('div',{staticClass:"datails_contant"},[_c('el-form',{staticClass:"between",attrs:{"label-width":_vm.type == 2 ? '116px' : '78px'}},[_c('el-form-item',{attrs:{"label":"模板名称"}},[_c('div',{staticClass:"news"},[_vm._v(_vm._s(_vm.fileList.reportName))])]),_c('el-form-item',{attrs:{"label":"广告主名称"}},[_c('div',{staticClass:"news"},[_vm._v(_vm._s(_vm.fileList.account))])]),_c('el-form-item',{staticStyle:{"width":"59%"},attrs:{"label":"报表名称"}},[_c('div',{staticClass:"news"},[_vm._v(_vm._s(_vm.fileList.title))])]),_c('el-form-item',{staticStyle:{"width":"39%"},attrs:{"label":"报表维度"}},[_c('div',{staticClass:"news"},[_vm._v(_vm._s(_vm.adTypeList[_vm.fileList.adType] || "-"))])]),(
          _vm.fileList.excelConfig &&
          _vm.fileList.excelConfig.dataSource &&
          _vm.fileList.excelConfig.dataSource.length > 0
        )?_c('div',{staticClass:"width_100"},[_c('div',{staticClass:"titles"},[_vm._v("数据源sheet")]),_vm._l((_vm.fileList.excelConfig.dataSource),function(item,i){return _c('el-form-item',{key:i,attrs:{"label":item.name}},_vm._l((item.owners),function(ele,j){return _c('div',{key:j,staticClass:"news"},[(j > 0)?_c('span',[_vm._v("、")]):_vm._e(),_vm._v(_vm._s(ele.ownerId)+" ")])}),0)})],2):_vm._e(),(
          _vm.fileList.excelConfig &&
          _vm.fileList.excelConfig.keyword &&
          _vm.fileList.excelConfig.keyword.length > 0
        )?_c('div',{staticClass:"width_100"},[_c('div',{staticClass:"titles"},[_vm._v("关键词")]),_vm._l((_vm.fileList.excelConfig.keyword),function(item,i){return _c('el-form-item',{key:i,attrs:{"label":item.name}},_vm._l((item.owners),function(ele,j){return _c('div',{key:j,staticClass:"news"},[(j > 0)?_c('span',[_vm._v("、")]):_vm._e(),_vm._v(_vm._s(ele.ownerId)+" ")])}),0)})],2):_vm._e(),_c('div',{staticClass:"titles"},[_vm._v("其他")]),_c('el-form-item',{attrs:{"label":"接收人"}},[_c('div',{staticClass:"news"},[_vm._v(_vm._s(_vm.fileList.senderName))])]),_c('el-form-item',{staticStyle:{"width":"49%"},attrs:{"label":"抄送人"}},[_c('div',{staticClass:"news"},[_vm._v(_vm._s(_vm.fileList.copyUserName))])]),_c('el-form-item',{staticStyle:{"width":"49%"},attrs:{"label":"接收方式"}},[_c('div',{staticClass:"news"},[_vm._v(_vm._s(_vm.fileList.senderF))])]),_c('el-form-item',{style:({width: _vm.type == 2 ? '49%' : ''}),attrs:{"label":"接收时间"}},[_c('div',{staticClass:"news"},[_vm._v(_vm._s(_vm.fileList.time))])]),(_vm.type == 2)?_c('el-form-item',{staticStyle:{"width":"49%","margin-bottom":"10px"},attrs:{"label":"是否拉取零时数据"}},[_c('div',{staticClass:"news"},[_c('el-switch',{attrs:{"disabled":"","active-value":2,"inactive-value":1},model:{value:(_vm.fileList.isNeedYesterday),callback:function ($$v) {_vm.$set(_vm.fileList, "isNeedYesterday", $$v)},expression:"fileList.isNeedYesterday"}})],1)]):_vm._e()],1)],1),_c('div',{attrs:{"slot":"footer"},slot:"footer"},[_c('el-button',{attrs:{"type":"primary"},on:{"click":_vm.onCancel}},[_vm._v("确定")])],1)])
}
var staticRenderFns = []

export { render, staticRenderFns }